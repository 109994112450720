import React, { useState, useRefs, useEffect } from "react"
import Layout from "../../components/layouts"
// import { ReactComponent as Animals } from "../../images/svgs/animals.svg"
import AnimalSVG from "../../images/svgs/AnimalSVG"

function Test() {


  return (
    <Layout>
      <div className="walk_contain">
        <section className="walk" style={{position: 'relative'}}>

         
            <AnimalSVG/>


        </section>
      </div>
    </Layout>
  )
}

export default Test
