import React, { useState } from "react"
import Lottie from "react-lottie"
import Chicken from "../../lotties/chair.json"
import Horse from "../../lotties/horse.json"
import Paw from "../../lotties/paw.json"
import Cow from "../../lotties/cow.json"
import Final from "../../lotties/Final.json"
import Rewards from "../../lotties/Rewards.json"

function AnimalSVG() {
  const [zoomed, setZoomed] = useState(false)
  const [style, setStyle] = useState(undefined)

  const chickenOptions = {
    loop: true,
    autoplay: false,
    animationData: Chicken,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const horseOptions = {
    loop: true,
    autoplay: false,
    animationData: Rewards,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const pawOptions = {
    loop: true,
    autoplay: false,
    animationData: Paw,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const cowOptions = {
    loop: true,
    autoplay: false,
    animationData: Cow,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const finalOptions = {
    loop: true,
    autoplay: false,
    animationData: Final,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }


  // Button onClicks

  const zoomRed = () => {
    setZoomed(true)
    setStyle(colors[0])
  }

  const zoomBlue = () => {
    setZoomed(true)
    setStyle(colors[1])
  }

  const zoomGreen = () => {
    setZoomed(true)
    setStyle(colors[2])
  }

  const zoomYellow = () => {
    setZoomed(true)
    setStyle(colors[3])
  }

  const zoomOut = () => {
    setZoomed(false)
    setStyle(undefined)
  }

  // Styles array - classNames in base.scss

  const colors = ["moveRed", "moveBlue", "moveGreen", "moveYellow"]

  const next = () => {
    let getNext = colors.indexOf(style)

    setStyle(colors[getNext + 1])
  }

  const prev = () => {
    let getPrev = colors.indexOf(style)

    // console.log(colors[getPrev - 1])

    setStyle(colors[getPrev - 1])
  }

  console.log(colors.indexOf(style + 1))

  return (
    <>
      <div
        className="action_bar"
        style={style === undefined ? { display: "none" } : { display: "block" }}
      >
        {style === "moveRed" ? (
          <div>
            <h1>I'm a cow.</h1>
          </div>
        ) : null}

        {style === "moveBlue" ? (
          <div>
            <h1>I'm a horse.</h1>
          </div>
        ) : null}

        {style === "moveGreen" ? (
          <div>
            <h1>I'm a turkey.</h1>
          </div>
        ) : null}

        {style === "moveYellow" ? (
          <div>
            <h1>I'm a doggo.</h1>
          </div>
        ) : null}

        <div className="action_btn_contain">
          <button onClick={prev}>prev</button>

          <button
            onClick={zoomOut}
            // style={ zoomed ? { display:'block'} : {display : 'none'} }
          >
            close
          </button>

          <button
            onClick={next}
            // style={ zoomed ? { display:'block'} : {display : 'none'} }
          >
            next
          </button>
        </div>
      </div>

      <div
        className={"aninmal_container " + style}
        style={{ transition: "0.5s ease-in-out" }}
      >

        <div className="chicken_container">
          <Lottie
            options={finalOptions}
            isStopped={style !== "moveGreen" ? true : false}
            isClickToPauseDisabled={true}
            height={"auto"}
            width={"100%"}
          />
        </div>

        <div className="horse_container">
          <Lottie
            options={horseOptions}
            isStopped={style !== "moveBlue" ? true : false}
            isClickToPauseDisabled={true}
            height={"auto"}
            width={"100%"}
          />
        </div>

        <div className="paw_container">
          <Lottie
            options={pawOptions}
            isStopped={style !== "moveYellow" ? true : false}
            isClickToPauseDisabled={true}
            height={"auto"}
            width={"100%"}
          />
        </div>

        <div className="cow_container">
          <Lottie
            options={cowOptions}
            isStopped={style !== "moveRed" ? true : false}
            isClickToPauseDisabled={true}
            height={"auto"}
            width={"100%"}
          />
        </div>

        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          xlink="/svgs/animals.jpg"
          viewBox="0 0 1986.96 566.88"
        >
          <image
            width="8279"
            height="2362"
            transform="scale(0.24)"
            href="/svgs/animals.jpg"
          />

          {/* RED */}
          <circle
            className="red circle"
            cx="498.75"
            cy="277.19"
            r="17.75"
            onClick={zoomRed}
            style={
              style === undefined ? { display: "block" } : { display: "none" }
            }
          />

          {/* BLUE */}
          <circle
            className="blue circle"
            cx="991.75"
            cy="277.19"
            r="17.75"
            onClick={zoomBlue}
            style={
              style === undefined ? { display: "block" } : { display: "none" }
            }
          />

          {/* GREEN */}
          <circle
            className="green circle"
            cx="1649"
            cy="450.44"
            r="16"
            onClick={zoomGreen}
            style={
              style === undefined ? { display: "block" } : { display: "none" }
            }
          />

          {/* YELLOW */}
          <circle
            className="yellow circle"
            cx="389.5"
            cy="470.94"
            r="20.5"
            onClick={zoomYellow}
            style={
              style === undefined ? { display: "block" } : { display: "none" }
            }
          />
        </svg>
      </div>
    </>
  )
}

export default AnimalSVG
